define("discourse/plugins/discourse-journal/discourse/components/journal-topic-tip", ["exports", "@ember/component", "discourse/lib/text", "@ember/runloop", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _text, _runloop, _isComponent, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    class="btn btn-topic-tip"
    @action={{action "toggleDetails"}}
    @label=label
    @icon="info-circle"
  />
  
  {{#if showDetails}}
    <div class="tip-details">
      {{cookedDetails}}
    </div>
  {{/if}}
  
  */
  {
    "id": "Mu+5Fvc9",
    "block": "[[[8,[39,0],[[24,0,\"btn btn-topic-tip\"]],[[\"@action\",\"@label\",\"@icon\"],[[28,[37,1],[[30,0],\"toggleDetails\"],null],\"label\",\"info-circle\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showDetails\"]],[[[1,\"  \"],[10,0],[14,0,\"tip-details\"],[12],[1,\"\\n    \"],[41,[28,[32,0],[\"cookedDetails\"],null],[[[8,[39,3],null,null,null]],[]],[[[44,[[28,[37,5],null,[[\"cookedDetails\"],[[28,[32,1],[\"cookedDetails\"],null]]]]],[[[1,[52,[30,1,[\"cookedDetails\"]],[28,[30,1,[\"cookedDetails\"]],null,null],[28,[32,2],[[30,0],\"cookedDetails\",\"[\\\"The `cookedDetails` property path was used in the `discourse/plugins/discourse-journal/discourse/components/journal-topic-tip.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.cookedDetails}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,3],[\"[[\\\"The `showDetails` property path was used in the `discourse/plugins/discourse-journal/discourse/components/journal-topic-tip.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showDetails}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"d-button\",\"action\",\"if\",\"cooked-details\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-journal/discourse/components/journal-topic-tip.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    classNames: ["journal-topic-tip"],
    didInsertElement() {
      this._super(...arguments);
      $(document).on("click", (0, _runloop.bind)(this, this.documentClick));
      const rawDetails = I18n.t(this.details);
      if (rawDetails) {
        (0, _text.cook)(rawDetails).then(cooked => {
          this.set("cookedDetails", cooked);
        });
      }
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    },
    documentClick(e) {
      const $element = $(this.element);
      const $target = $(e.target);
      if ($target.closest($element).length < 1 && this._state !== "destroying") {
        this.set("showDetails", false);
      }
    },
    actions: {
      toggleDetails() {
        this.toggleProperty("showDetails");
      }
    }
  }));
});