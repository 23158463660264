define("discourse/plugins/discourse-journal/discourse/templates/connectors/after-topic-footer-main-buttons/entry-button-wrapper", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if topic.can_create_entry}}
     <DButton
      class="btn-primary create entry"
      @icon="reply"
      @action={{action "createEntry"}}
      @label="topic.entry.title"
      @title="topic.entry.title"
     />
  {{/if}}
  
  */
  {
    "id": "WpOq2wSj",
    "block": "[[[41,[30,0,[\"topic\",\"can_create_entry\"]],[[[1,\"   \"],[8,[39,1],[[24,0,\"btn-primary create entry\"]],[[\"@icon\",\"@action\",\"@label\",\"@title\"],[\"reply\",[28,[37,2],[[30,0],\"createEntry\"],null],\"topic.entry.title\",\"topic.entry.title\"]],null],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `topic` property path was used in the `discourse/plugins/discourse-journal/discourse/templates/connectors/after-topic-footer-main-buttons/entry-button-wrapper.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topic}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-journal/discourse/templates/connectors/after-topic-footer-main-buttons/entry-button-wrapper.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});