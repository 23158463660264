define("discourse/plugins/discourse-journal/discourse/initializers/journal-discovery", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-journal";
  var _default = _exports.default = {
    name: "journal-discovery",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.journal_enabled) return;
      (0, _pluginApi.withPluginApi)('0.8.12', api => {
        api.modifyClass('component:d-navigation', dt7948.p({
          pluginId: PLUGIN_ID,
          createTopicLabel(hasDraft, journalCategory) {
            if (journalCategory) {
              return "topic.create_journal.label";
            } else {
              return this._super(...arguments);
            }
          }
        }, [["method", "createTopicLabel", [(0, _decorators.default)("hasDraft", "category.journal")]]]));
        api.modifyClass('route:discovery', {
          pluginId: PLUGIN_ID,
          discoveryCategory() {
            if (this.router.currentRouteName == "discovery.category") {
              return this.router.currentRoute.attributes.category;
            } else {
              return null;
            }
          },
          actions: {
            didTransition() {
              const category = this.discoveryCategory();
              if (category && category.journal) {
                $("body").addClass("journal-category");
              }
              return this._super();
            },
            willTransition() {
              const category = this.discoveryCategory();
              if (category && category.journal) {
                $("body").removeClass("journal-category");
              }
              return this._super();
            }
          }
        });
      });
    }
  };
});